





























































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import type { CustomJobExecution } from '../typings';
import { cancelOtaJobExecution, getOtaJobExecutionsList } from '../api/admin';
import { Hub } from '@aws-amplify/core';
import {
  mdiCloseCircleOutline,
  mdiCancel,
  mdiCheckCircleOutline,
  mdiClockAlertOutline,
  mdiDeleteCircleOutline,
  mdiProgressDownload,
  mdiClockFast,
  mdiHelpCircleOutline,
  mdiRefresh,
  mdiTablePlus,
  mdiMagnify
} from '@mdi/js';
import _ from 'lodash';
import { THING_NAME_PREFIX } from '../constants';

@Component
export default class JobExecutionsTableComponent extends Vue {
  private readonly mdiMagnify: string = mdiMagnify;
  private readonly mdiRefresh: string = mdiRefresh;
  private readonly mdiCancel: string = mdiCancel;
  private readonly mdiTablePlus: string = mdiTablePlus;
  private readonly mdiCloseCircleOutline: string = mdiCloseCircleOutline;
  private readonly mdiDeleteCircleOutline: string = mdiDeleteCircleOutline;
  private readonly mdiProgressDownload: string = mdiProgressDownload;
  private readonly mdiClockFast: string = mdiClockFast;
  private readonly mdiCheckCircleOutline: string = mdiCheckCircleOutline;
  private readonly mdiClockAlertOutline: string = mdiClockAlertOutline;
  private readonly mdiHelpCircleOutline: string = mdiHelpCircleOutline;

  private sortBy: string | string[] = 'status';
  private sortDesc: boolean = false;
  private loading: boolean = true;
  private tableItems: CustomJobExecution[] = [];
  private searchTable: string = '';
  private selected: CustomJobExecution[] = [];

  private get tableHeaders(): Array<{
    text: string;
    value: string;
    sortable: boolean;
    width?: string | number;
    align?: 'start' | 'center' | 'end';
  }> {
    return [
      {
        text: this.$vuetify.lang.t('$vuetify.executions.UID'),
        value: 'uid',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.executions.STATUS'),
        value: 'status',
        sortable: true,
        align: 'center'
      }
    ];
  }

  private get tableHeight(): number {
    const maxHeight: number =
      this.tableItems.length <= 50
        ? window.innerHeight - 289
        : window.innerHeight - 348;
    return Math.min(maxHeight, this.tableItems.length * 49 + 49);
  }

  @Prop({
    type: String,
    required: true
  })
  public jobId!: string;

  @Watch('selected', { deep: true })
  onSelectionChanged(selected: CustomJobExecution[]): void {
    this.$emit(
      'selected',
      selected.map(({ arn }: CustomJobExecution): string => arn)
    );
  }

  private mounted(): void {
    this.getOtaJobExecutionsList();
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private getOtaJobExecutionsList(): void {
    this.loading = true;
    getOtaJobExecutionsList(this.jobId)
      .then(
        (list: CustomJobExecution[]): void => void (this.tableItems = list)
      )
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private cancelOtaJobExecution(): void {
    this.loading = true;
    Promise.all(
      this.selected.map(
        (target: CustomJobExecution): Promise<void> =>
          cancelOtaJobExecution(
            this.jobId,
            `${THING_NAME_PREFIX}${target.uid}`
          )
      )
    )
      .then((): void => {
        this.tableItems = _.unionBy(
          this.selected.map(
            (item: CustomJobExecution): CustomJobExecution => ({
              ...item,
              status: 'CANCELED'
            })
          ),
          this.tableItems,
          'arn'
        );
        this.selected = [];
      })
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }
}
