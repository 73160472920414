






























































































































































































































































































































































































































































































import {
  addOtaJob,
  getOtaJobList,
  getThingList,
  getFirmwareLocationList,
  removeOtaJob
} from '../../api/admin';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  mdiRefresh,
  mdiMagnify,
  mdiTablePlus,
  mdiClose,
  mdiCloudSearch,
  mdiListStatus
} from '@mdi/js';
import type { ValidationRule } from '../../typings';
import { noop } from 'vue-class-component/lib/util';
import {
  OTAUpdateInfo,
  S3Location,
  TargetSelection
} from '@aws-sdk/client-iot';
import { SettingsModule } from '../../plugins/store';
import JobExecutionsTableComponent from '../../components/JobExecutionsTableComponent.vue';
import { getModule } from 'vuex-module-decorators';
import { Hub } from '@aws-amplify/core';
import semver from 'semver';
import {
  THING_GROUP_ALL,
  THING_GROUP_GATEWAY,
  THING_GROUP_INTERFACE,
  THING_GROUP_OTHER,
  THING_ARN_PREFIX,
  THING_GROUP_ARN_PREFIX,
  THING_NAME_PREFIX
} from '../../constants';

interface AutocompleteItem {
  text?: string;
  value?: string | S3Location;
  disabled?: boolean;
  divider?: boolean;
  header?: string;
}

const settingsStore: SettingsModule = getModule(SettingsModule);

@Component({
  components: { JobExecutionsTableComponent }
})
export default class OtaJobTableView extends Vue {
  private readonly THING_NAME_PREFIX: string = THING_NAME_PREFIX;
  private readonly THING_GROUP_ALL: string = THING_GROUP_ALL;
  private readonly THING_GROUP_GATEWAY: string = THING_GROUP_GATEWAY;
  private readonly THING_GROUP_INTERFACE: string = THING_GROUP_INTERFACE;
  private readonly THING_GROUP_OTHER: string = THING_GROUP_OTHER;
  private readonly mdiRefresh: string = mdiRefresh;
  private readonly mdiTablePlus: string = mdiTablePlus;
  private readonly mdiClose: string = mdiClose;
  private readonly mdiMagnify: string = mdiMagnify;
  private readonly mdiCloudSearch: string = mdiCloudSearch;
  private readonly mdiListStatus: string = mdiListStatus;
  private readonly noop: typeof noop = noop;
  private readonly strategyItems: Array<{
    text: string;
    value: TargetSelection;
  }> = Object.values(TargetSelection).map(
    (value: TargetSelection): { text: string; value: TargetSelection } => ({
      text: `$vuetify.jobs.${value}`,
      value
    })
  );
  private readonly requiredRule: ValidationRule = (
    v: string | string[]
  ): true | string =>
    (!!v && (!Array.isArray(v) || !!v.length)) || '$vuetify.jobs.REQUIRED';
  private readonly versionRule: ValidationRule = (v: string): true | string =>
    !v || semver.valid(v) !== null || '$vuetify.jobs.INVALID';

  private dialogShow: boolean = false;
  private dialogValid: boolean = false;
  private dialogName: string = '';
  private dialogVersion: string = '';
  private dialogTargets: string[] = [];
  private dialogFirmware: S3Location | null = null;
  private dialogStrategy: TargetSelection = TargetSelection.SNAPSHOT;
  private loading: boolean = true;
  private targetsLoading: boolean = true;
  private firmwareLoading: boolean = true;
  private sortBy: string = 'creationDate';
  private sortDesc: boolean = true;
  private jobExecutions: OTAUpdateInfo | null = null;
  private removeJob: string = '';
  private tableItems: OTAUpdateInfo[] = [];
  private things: string[] = [];
  private firmwareItems: AutocompleteItem[] = [];

  private get tableHeaders(): Array<{
    text: string;
    value: string;
    sortable: boolean;
    width?: string | number;
    align?: 'start' | 'center' | 'end';
  }> {
    return [
      {
        text: this.$vuetify.lang.t('$vuetify.jobs.NAME'),
        value: 'otaUpdateId',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.jobs.FIRMWARE'),
        value: 'otaUpdateFiles',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.jobs.STRATEGY'),
        value: 'targetSelection',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.jobs.TARGETS'),
        value: 'targets',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.jobs.CREATED'),
        value: 'creationDate',
        sortable: true
      },
      {
        text: '',
        value: 'remove',
        sortable: false,
        width: '0',
        align: 'end'
      }
    ];
  }

  private get showConsole(): boolean {
    return settingsStore.console;
  }

  private get targetItems(): AutocompleteItem[] {
    const gateways: string[] = this.things.filter((item: string): boolean =>
      item.startsWith('G-')
    );
    const interfaces: string[] = this.things.filter((item: string): boolean =>
      item.startsWith('I-')
    );
    const others: string[] = this.things.filter(
      (item: string): boolean =>
        !item.startsWith('G-') && !item.startsWith('I-')
    );
    const targetItems: AutocompleteItem[] = [
      {
        header: this.$vuetify.lang.t('$vuetify.jobs.GROUPS')
      },
      {
        value: THING_GROUP_ALL,
        text: this.$vuetify.lang.t('$vuetify.jobs.ALL_DEVICES')
      },
      {
        value: THING_GROUP_GATEWAY,
        text: this.$vuetify.lang.t('$vuetify.jobs.ALL_GATEWAYS')
      },
      {
        value: THING_GROUP_INTERFACE,
        text: this.$vuetify.lang.t('$vuetify.jobs.ALL_INTERFACES')
      },
      {
        value: THING_GROUP_OTHER,
        text: this.$vuetify.lang.t('$vuetify.jobs.ALL_OTHERS')
      }
    ];
    if (gateways.length) {
      targetItems.push(
        {
          header: this.$vuetify.lang.t('$vuetify.jobs.GATEWAYS')
        },
        ...gateways.sort().map(
          (item: string): AutocompleteItem => ({
            value: `${THING_ARN_PREFIX}${item}`,
            text: item
          })
        )
      );
    }
    if (interfaces.length) {
      targetItems.push(
        {
          header: this.$vuetify.lang.t('$vuetify.jobs.INTERFACES')
        },
        ...interfaces.sort().map(
          (item: string): AutocompleteItem => ({
            value: `${THING_ARN_PREFIX}${item}`,
            text: item
          })
        )
      );
    }
    if (others.length) {
      targetItems.push(
        {
          header: this.$vuetify.lang.t('$vuetify.jobs.OTHERS')
        },
        ...others.sort().map(
          (item: string): AutocompleteItem => ({
            value: `${THING_ARN_PREFIX}${item}`,
            text: item
          })
        )
      );
    }
    return targetItems;
  }

  @Prop({ type: [String, Boolean], default: false })
  private readonly searchTable!: string | false;

  @Watch('dialogFirmware', { deep: true })
  onFirmwareChanged(firmware: S3Location | null): void {
    if (firmware === null || this.dialogVersion) {
      return;
    }
    this.dialogVersion =
      semver.clean(
        (firmware.key || '').replace(/^.*?(?:-V)?(\d+\..*?)\.bin$/i, '$1')
      ) || '';
  }

  private mounted(): void {
    this.getOtaJobList();
    this.getThingList();
    this.getFirmwareLocationList();
  }

  private uniqueRule(): ValidationRule {
    return (v: string): true | string =>
      this.tableItems.every(
        (item: OTAUpdateInfo): boolean => item.otaUpdateId !== v
      ) || '$vuetify.jobs.UNIQUE';
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private getOtaJobList(): void {
    this.loading = true;
    getOtaJobList()
      .then((list: OTAUpdateInfo[]): void => void (this.tableItems = list))
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private addOtaJob(): void {
    if (
      !this.dialogName ||
      !this.dialogVersion ||
      !this.dialogTargets ||
      !this.dialogFirmware ||
      !this.dialogStrategy
    ) {
      return;
    }
    this.loading = true;
    addOtaJob(
      this.dialogName,
      this.dialogVersion,
      this.dialogTargets,
      this.dialogFirmware,
      this.dialogStrategy
    )
      .then((job: OTAUpdateInfo): void => {
        this.tableItems.push(job);
        this.resetDialog();
        this.sortBy = 'creationDate';
        this.sortDesc = true;
      })
      .catch((error: Error): void => {
        this.dialogShow = false;
        this.showError(error.message);
      })
      .finally((): void => void (this.loading = false));
  }

  private removeOtaJob(): void {
    const index: number = this.tableItems.findIndex(
      (item: OTAUpdateInfo): boolean => item.otaUpdateId === this.removeJob
    );
    if (index < 0) {
      return;
    }
    const otaUpdateId: string = this.removeJob;
    this.loading = true;
    this.removeJob = '';
    removeOtaJob(otaUpdateId)
      .then((): void => void this.tableItems.splice(index, 1))
      .catch((error: Error): void => void this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private getThingList(): void {
    this.targetsLoading = true;
    getThingList()
      .then((things: string[]): void => void (this.things = things))
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.targetsLoading = false));
  }

  private checkDeviceInSelectedGroup(item: AutocompleteItem): boolean {
    if (typeof item.value !== 'string' || item.value === THING_GROUP_ALL) {
      return false;
    }
    if (this.dialogTargets.includes(THING_GROUP_ARN_PREFIX)) {
      return true;
    }
    if (item.value.includes('thinggroup')) {
      return false;
    }
    return this.dialogTargets.includes(
      item.value.startsWith(`${THING_ARN_PREFIX}G-`)
        ? THING_GROUP_GATEWAY
        : item.value.startsWith(`${THING_ARN_PREFIX}I-`)
        ? THING_GROUP_INTERFACE
        : THING_GROUP_OTHER
    );
  }

  private updateDialogTargets(targets: string[]): void {
    if (targets.includes(THING_GROUP_ALL)) {
      targets = [THING_GROUP_ALL];
    }
    if (targets.includes(THING_GROUP_GATEWAY)) {
      targets = targets.filter(
        (target: string): boolean =>
          !target.startsWith(`${THING_ARN_PREFIX}G-`)
      );
    }
    if (targets.includes(THING_GROUP_INTERFACE)) {
      targets = targets.filter(
        (target: string): boolean =>
          !target.startsWith(`${THING_ARN_PREFIX}I-`)
      );
    }
    if (targets.includes(THING_GROUP_OTHER)) {
      targets = targets.filter(
        (target: string): boolean =>
          target.includes('thinggroup') ||
          target.startsWith(`${THING_ARN_PREFIX}G-`) ||
          target.startsWith(`${THING_ARN_PREFIX}I-`)
      );
    }
    this.dialogTargets = targets;
  }

  private getFirmwareLocationList(): void {
    this.firmwareLoading = true;
    getFirmwareLocationList()
      .then(
        (files: S3Location[]): void =>
          void (this.firmwareItems = files.map(
            (file: S3Location): AutocompleteItem => ({
              text: file.key,
              value: file
            })
          ))
      )
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.firmwareLoading = false));
  }

  private retryUpdate(): void {
    if (!this.jobExecutions) {
      return;
    }
    this.dialogVersion =
      this.jobExecutions.additionalParameters?.version || '';
    this.dialogFirmware =
      this.jobExecutions.otaUpdateFiles?.[0]?.fileLocation?.s3Location ||
      null;
    this.dialogStrategy =
      (this.jobExecutions.targetSelection as TargetSelection) ||
      TargetSelection.SNAPSHOT;
    this.jobExecutions = null;
    this.dialogShow = true;
  }

  private resetDialog(): void {
    this.dialogShow = false;
    this.dialogName = '';
    this.dialogVersion = '';
    this.dialogTargets = [];
    this.dialogFirmware = null;
    this.dialogStrategy = TargetSelection.SNAPSHOT;
    this.jobExecutions = null;
  }
}
