var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.tableItems,"headers":_vm.tableHeaders,"loading":_vm.loading,"search":_vm.searchTable,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":"","item-key":"uid","show-select":"","fixed-header":"","height":_vm.tableHeight,"hide-default-footer":_vm.tableItems.length <= 50,"items-per-page":50,"footer-props":{
    itemsPerPageOptions: [50],
    disablePagination: _vm.tableItems.length <= 50,
    disableItemsPerPage: _vm.tableItems.length <= 50
  }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('v-spacer',{staticStyle:{"height":"58px"}})]},proxy:true},{key:"top",fn:function(){return [_c('v-row',{staticClass:"flex-nowrap",attrs:{"dense":"","no-gutters":"","align":"center"}},[_c('v-text-field',{staticClass:"mt-0",staticStyle:{"margin-right":"6px"},attrs:{"dense":"","hide-details":"","single-line":"","autofocus":"","label":_vm.$vuetify.lang.t('$vuetify.executions.SEARCH'),"placeholder":_vm.$vuetify.lang.t('$vuetify.executions.SEARCH'),"type":"search"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();_vm.searchTable = ''}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticStyle:{"margin-top":"5px","margin-bottom":"5px"},attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiMagnify)+" ")])]},proxy:true}]),model:{value:(_vm.searchTable),callback:function ($$v) {_vm.searchTable=$$v},expression:"searchTable"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","disabled":!_vm.selected.length},on:{"click":function($event){return _vm.$emit('retry')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiTablePlus))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( ("$vuetify.executions." + (_vm.selected.length > 1 ? 'RETRY_EXECUTIONS' : 'RETRY_EXECUTION')) ))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","disabled":!_vm.selected.length},on:{"click":_vm.cancelOtaJobExecution}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiCancel))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( ("$vuetify.executions." + (_vm.selected.length > 1 ? 'CANCEL_EXECUTIONS' : 'CANCEL_EXECUTION')) ))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.getOtaJobExecutionsList}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiRefresh))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.executions.REFRESH'))+" ")])])],1)]},proxy:true},{key:"item.uid",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({staticClass:"flex-nowrap",staticStyle:{"min-height":"100%","cursor":"pointer"},attrs:{"dense":"","no-gutters":"","align":"center"},on:{"click":function($event){return _vm.$router.push(("/shadows/" + (item.uid) + "?search=" + (item.uid)))}}},'v-row',attrs,false),on),[_vm._v(" "+_vm._s(item.uid)+" "),_c('v-spacer')],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.executions.OPEN_SHADOW'))+" ")])])]}},{key:"item.lastUpdatedAt",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.lastUpdatedAt))+" ")]}},{key:"item.queuedAt",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.queuedAt))+" ")]}},{key:"item.startedAt",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.startedAt))+" ")]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":[
              'CANCELED',
              'FAILED',
              'REJECTED',
              'REMOVED',
              'TIMED_OUT'
            ].includes(item.status)
              ? 'error'
              : item.status === 'SUCCEEDED'
              ? 'success'
              : undefined}},'v-icon',attrs,false),on),[_vm._v(_vm._s(item.status === 'CANCELED' ? _vm.mdiCancel : item.status === 'FAILED' ? _vm.mdiCloseCircleOutline : item.status === 'IN_PROGRESS' ? _vm.mdiProgressDownload : item.status === 'QUEUED' ? _vm.mdiClockFast : item.status === 'REJECTED' ? _vm.mdiCancel : item.status === 'REMOVED' ? _vm.mdiDeleteCircleOutline : item.status === 'SUCCEEDED' ? _vm.mdiCheckCircleOutline : item.status === 'TIMED_OUT' ? _vm.mdiClockAlertOutline : _vm.mdiHelpCircleOutline))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.executions." + (item.status))))+" ")])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }